<template>
  <div class="create-responsibilities">
    <div class="create-responsibilities__modal">
      <Widget type="loader-alternative" v-if="loading" />
      <div v-else class="create-responsibilities__container">
        <div class="create-responsibilities__header">
          <h4 class="create-responsibilities__title">
            {{ editMode ? "Редактирование Задачи" : "Добавление Задачи" }}
          </h4>
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @click="$root.$emit('showCreateChangeResponsibilitiesModal', null)"
          >
            <path
              d="M5 5L17 17M5 17L17 5L5 17Z"
              stroke="#B8B8CB"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <Widget
          type="input-title"
          unique="responsibilities-name"
          placeholder="Название задачи"
          :maxlength="70"
          :editValue="responsibilitiesName"
        />
        <Widget
          :entity="'User'"
          :actionEntity="'getItems'"
          :itemName="'full_name'"
          :keyValue="'responsibilities-responsible'"
          type="select-new"
          label="Ответственный"
          placeholder="Выберите ответственного"
          :multiple="false"
          :editValue="responsibilitiesResponsible"
        />
        <Widget
          :entity="'User'"
          :actionEntity="'getItems'"
          :itemName="'full_name'"
          :keyValue="'responsibilities-assistant'"
          type="select-new"
          label="Заместитель"
          placeholder="Выберите заместителя"
          :multiple="false"
          :editValue="responsibilitiesAssistant"
        />
        <Widget
          :entity="'User'"
          :actionEntity="'getItems'"
          :itemName="'full_name'"
          :keyValue="'responsibilities-users'"
          type="select-new"
          label="Участники"
          placeholder="Выберите участников"
          :multiple="true"
          :editValue="responsibilitiesUsers"
        />
        <Widget
          type="text"
          :maxlength="500"
          placeholder="Описание"
          label="Цель"
          unique="responsibilities-final"
          :editValue="responsibilitiesFinal"
        />
        <RegulationPicker
          :entity="'Regulation'"
          :actionEntity="'actionGetItems'"
          :itemName="'name'"
          :keyValue="'responsibilities-regulation'"
          type="select-new"
          label="Прикрепить инструкцию"
          placeholder="Выберите инструкцию"
          :multiple="false"
          :editValue="responsibilitiesRegulation"
        />
        <div class="create-responsibilities__buttons" v-if="!editMode">
          <ModalButton
            :type="'cancel'"
            :title="'Добавить еще'"
            @handleButton="responsibilitiesSave(false)"
          />
          <ModalButton :title="'Добавить'" @handleButton="responsibilitiesSave(true)" />
        </div>
        <div class="create-responsibilities__buttons" v-else>
          <ModalButton
            :type="'cancel'"
            :title="'Отменить'"
            @handleButton="$root.$emit('showCreateChangeResponsibilitiesModal', null)"
          />
          <ModalButton
            :title="editMode ? 'Сохранить' : 'Добавить'"
            @handleButton="responsibilitiesSave(true)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { domain } from "@/globalVariables";
import RegulationPicker from "@/components/Structure/RegulationPicker/RegulationPicker";
const Widget = () => import("@/components/Widgets/Widget");
import ModalButton from "@/components/Widgets/Button/ModalButton";
import httpClient from "@/api/client/httpClient";

export default {
  name: "ModalCreateChangeResponsibilities",
  mixins: [],
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    editMode: {
      type: Boolean
    }
  },
  components: { RegulationPicker, Widget, ModalButton },
  data() {
    return {
      loading: false,
      responsibilitiesName: "",
      responsibilitiesResponsible: [],
      responsibilitiesAssistant: [],
      responsibilitiesUsers: [],
      responsibilitiesFinal: "",
      responsibilitiesRegulation: []
    };
  },
  beforeDestroy() {
    this.$root.$off("text:change_responsibilities-name");
    this.$root.$off("changeSelectValue:responsibilities-responsible");
    this.$root.$off("changeSelectValue:responsibilities-assistant");
    this.$root.$off("changeSelectValue:responsibilities-users");
    this.$root.$off("textarea:change_responsibilities-final");
    this.$root.$off("changeSelectValue:responsibilities-regulation");
    this.$root.$off("button:click_cancel-modal");
    this.$root.$off("button:click_repeat-save-button");
    this.$root.$off("button:click_save-button");
  },
  created() {
    if (this.editMode) {
      this.saveEditData();
    }
    this.$root.$on("text:change_responsibilities-name", (value) => {
      this.responsibilitiesName = value.trim();
    });
    this.$root.$on("changeSelectValue:responsibilities-responsible", (value) => {
      this.responsibilitiesResponsible = value;
    });
    this.$root.$on("changeSelectValue:responsibilities-assistant", (value) => {
      this.responsibilitiesAssistant = value;
    });
    this.$root.$on("changeSelectValue:responsibilities-users", (value) => {
      this.responsibilitiesUsers = value;
    });
    this.$root.$on("textarea:change_responsibilities-final", (value) => {
      this.responsibilitiesFinal = value.trim();
    });
    this.$root.$on("changeSelectValue:responsibilities-regulation", (value) => {
      this.responsibilitiesRegulation = value.map((item) => item.id);
    });
    this.$root.$on("button:click_cancel-modal", () => {
      this.$root.$emit("showCreateChangeResponsibilitiesModal", null);
    });
    this.$root.$on("button:click_repeat-save-button", () => {
      this.responsibilitiesSave(false);
    });
    this.$root.$on("button:click_save-button", () => {
      this.responsibilitiesSave(true);
    });
  },
  methods: {
    // Создание задачи
    responsibilitiesSave(closeModal) {
      if (!this.checkValidation()) return false;
      httpClient({
        url: `/Responsibility/${this.editMode ? "actionUpdate" : "actionCreate"}${
          this.editMode ? `/${this.data.id}` : ""
        }`,
        method: this.editMode ? "PUT" : "POST",
        data: [
          {
            name: this.responsibilitiesName.trim(),
            final_product: this.responsibilitiesFinal.trim(),
            responsible_user_id: this.responsibilitiesResponsible.length
              ? this.responsibilitiesResponsible.map((item) => item.id)[0]
              : undefined,
            assistant_user_id: this.responsibilitiesAssistant.length
              ? this.responsibilitiesAssistant.map((item) => item.id)[0]
              : undefined,
            org_structure_item_id: !this.editMode ? this.data.id : undefined,
            regulation_id: this.responsibilitiesRegulation.length
              ? this.responsibilitiesRegulation[0]
              : null,
            users: [
              ...new Set(
                this.responsibilitiesUsers
                  .map((item) => item.id)
                  .concat(this.responsibilitiesResponsible.map((item) => item.id))
                  .concat(this.responsibilitiesAssistant.map((item) => item.id))
              )
            ]
          }
        ]
      })
        .then(() => {
          this.$root.$emit("updateResponsibilityInfo");
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 3000,
            label: this.editMode ? "Задача успешно изменена." : "Задача успешно добавлена."
          });
          if (closeModal) {
            this.$root.$emit("showCreateChangeResponsibilitiesModal", null);
            this.$root.$emit("updateStructureChart");
          }
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    saveEditData() {
      this.responsibilitiesName = this.data.name;
      this.responsibilitiesFinal = this.data.final_product;
      this.responsibilitiesResponsible = this.data.responsible_user
        ? [this.data.responsible_user]
        : [];
      this.responsibilitiesUsers = this.data.users ? this.data.users : [];
      this.responsibilitiesRegulation = this.data.regulation ? [this.data.regulation] : [];
      this.responsibilitiesAssistant = this.data.assistant_user ? [this.data.assistant_user] : [];
    },
    // Проверка валидации
    checkValidation() {
      if (!this.responsibilitiesName.trim()) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: "Введите название задачи!"
        });
        return false;
      }
      if (this.responsibilitiesName.length < 2 || this.responsibilitiesName.length > 70) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: "Название должно быть не меньше 2 и не более 70 символов!"
        });
        return false;
      }
      if (!this.responsibilitiesResponsible.length && !this.responsibilitiesAssistant.length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: "Должен быть минимум один Ответственный или Заместитель!"
        });
        return false;
      }
      if (!this.responsibilitiesUsers.length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: "Должен быть минимум один участник!"
        });
        return false;
      }
      if (!this.responsibilitiesFinal.trim()) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: "Введите описание Цели!"
        });
        return false;
      }
      if (this.responsibilitiesFinal.length < 6 || this.responsibilitiesFinal.length > 500) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: "Описание Цели должно быть не меньше 6 и не более 500 символов!"
        });
        return false;
      }
      return true;
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.create-responsibilities {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: $background-modal;

  &__modal {
    position: relative;
    display: flex;
    min-width: 490px;
    max-height: 80vh;
    overflow-y: auto;
    padding: 0 20px 20px;
    background: $surface-default;
    box-shadow: 0 0 1px rgba(12, 26, 75, 0.1), 0 10px 16px rgba(20, 37, 63, 0.02);
    border-radius: 24px;

    @media screen and (max-width: 540px) {
      min-height: auto;
      min-width: auto;
      max-width: 320px;
      max-height: 80vh;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 25px 0;
    padding-top: 20px;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 100;

    svg:hover {
      cursor: pointer;

      path {
        stroke: $color-interactive;
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .form-group-input-text {
      margin-bottom: 10px;

      /deep/ {
        .form-group__label {
          font-size: 12px;
        }
      }
    }

    .form-group-textarea {
      margin-bottom: 10px;

      /deep/ {
        .form-group__label {
          font-size: 12px;
        }

        textarea {
          height: 70px;
        }
      }
    }

    .select-bg {
      width: 100%;
      margin-bottom: 10px;

      /deep/ {
        .select-label {
          font-size: 12px;
        }

        .select-content-bg {
          height: 34px;
        }
      }
    }
  }

  &__title {
    padding: 0;
    margin: 0;

    font-size: 18px;
    line-height: 20px;
    color: $color-text-accent;
    font-weight: 400;
  }

  &__buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
